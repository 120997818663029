<template>
    <div id="CommonQuestion"> 
    <Header/>      
    <body class=" bg-white"> 
<!-- header -->
    <div class="fixed-top bg-white">
    <nav class="navbar  pb-0 fixed-top nicole-noshadow">
      <div class="container">
        <a @click="onBack" class="navbar-brand font-weight-bolder me-0 col-4" rel="tooltip" data-placement="bottom" >
          <img class="avatar avatar-sm" src="assets/img/icon-back-black.png">
        </a>            
        <a class="navbar-brand font-weight-bold me-0 col-4 text-center" rel="tooltip" data-placement="bottom">
           常見問題
        </a>             
        <a class="navbar-brand font-weight-bolder me-0 text-right col-4" >
        </a> 
      </div>
    </nav>
        <div class="nicolespacetop"></div>
    <div class=" bg-white ">
      
    </div>
</div>        
<!-- End header -->
 
    <div class="nicolespacetop"></div>
    <!-- End header -->
	
	
	
<!-- header -->
<!--
    <div class="fixed-top bg-white">
    <nav class="navbar  pb-0 fixed-top nicole-noshadow">
      
    </nav>
	<div class="nicolespacetop"></div>
    <div class="">
    <section class="table-responsive nobar">    
    <div class="position-relative bg-white nav-maxw" style=" width: 100%;">
        <ul class="nav sliding-nav pb-2 " sn-color="#fff" sn-radius="10px" sn-height="4px" style="overflow:scroll;overflow-X:hidden; ">
		 <li class="nav-minw text-center mx-auto text-card-title text-center nav-item text-nav-gray" :class="(item.qmid == qmid)? 'selected auto':''" v-for="(item ) in pagequestion_mode" :key="item.qmid"  @click="onView(item.qmid,1)"  >{{item.name}}</li>
		  
        </ul>
    </div>
    </section> 
    </div>
</div>   
  -->
   
    <div class="">
    <section class="table-responsive nobar">    
    <div class="position-relative bg-white nav-maxw" style=" width: 100%;">
        <ul class="nav sliding-nav pb-2 " sn-color="#fff" sn-radius="10px" sn-height="4px" style="overflow:scroll;overflow-X:hidden; ">
		
		
		 <li class="nav-minw text-center mx-auto text-card-title text-center nav-item text-nav-gray " :class="(item.qmid == qmid)? 'selected auto':''" v-for="(item ) in pagequestion_mode" :key="item.qmid"  @click="onView(item.qmid,1)"  >{{item.name}}</li>
		  
        </ul>
    </div>
    </section> 
    </div>
	
<!--

        <div class="position-relative bg-white">
        <ul class="nav sliding-nav w-100 pb-2" sn-color="#fff" sn-radius="10px" sn-height="4px">      
             
           
			 <li class="mx-auto text-center text-card-title nav-item text-nav-gray selected auto" :class="(item.qmid == qmid)? ' mx-auto text-center text-card-title nav-item text-nav-gray selected auto':'text-center mx-auto text-card-title text-center nav-item text-nav-gray'" v-for="(item ) in pagequestion_mode" :key="item.qmid"  @click="onView(item.qmid,1)"  >{{item.name}}</li>
		 
        </ul>
        </div> 
 -->
		    
<div class=" mt-3 mt-md-4 bg-white">
<div class="container">
        <div class="accordion pb-3 " id="accordionRental">
       <!--下拉選單組-->
          <div class="accordion-item" v-for="(item,index) in getQuestion" v-bind:key='item.qid' >
		  
            <h5 class="accordion-header"  :id="'headingOne'+index">
			
              <button class="accordion-button collapsed " type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseOne'+index" aria-expanded="false" aria-controls="'collapseOne'+index">
                <div class="d-flex align-items-center">
                    <img src="assets/img/img051.png" class="avatar avatar-22 rounded-0 me-2 mt-1">
						 <!-- eslint-disable vue/no-v-html -->
                    <span class=" text-dark text-smm text-simbold text-left col-10"> {{item.name}}</span>
					<!--eslint-enable-->
                <i class="collapse-close fas fa-chevron-circle-down text-n pt-1 position-absolute end-0 text-light" aria-hidden="true"></i>
                <i class="collapse-open fas fa-chevron-circle-up text-n pt-1 position-absolute end-0 text-light" aria-hidden="true"></i> 
                  </div>
              </button>
            </h5>
            <div :id="'collapseOne'+index" class="accordion-collapse collapse"  :aria-labelledby="'headingOne'+index" data-bs-parent="#accordionRental">
                <div class="container mt-md-1 mb-md-3">
                    <!--一個組合-->
                    <div class="mb-2 px-3 py-2 bg-soft-light rounded-3">  
                        <h6 class="text-dark text-smm  text-simbold hide1">回覆：</h6>
						 <!-- eslint-disable vue/no-v-html -->
                        <h6 class="text-smm  text-dark" v-html="item.description"></h6>
						<!--eslint-enable-->
                    </div>  
                    <!--一個組合 end-->
                </div>
            </div>
          </div>
       <!--end下拉選單組-->  
       <!--下拉選單組-->    
	 
        <!--end下拉選單組-->        
        <!--下拉選單組-->    
		 
        <!--end下拉選單組--> 
        </div>
      </div>
      </div>
 
  
	
	  <scroll-loader :loader-method="getCommonQuestion" :loader-disable="disable">
        </scroll-loader> 
        
        <div class="nicolespace"></div>
        <div class="nicolespace"></div> 
    </body>
    <Footer/>
    </div>
</template>


<script>
import Vue from 'vue'
import ScrollLoader from 'vue-scroll-loader' 
Vue.use(ScrollLoader)

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue' 
import { oltConsole , oAC } from "@/lib/oltModule/initClient";
import moment from 'moment';


export default {
    name: 'CommonQuestion',
    components: {
        Header,
        Footer, 
    },
    data () {
    return { 
        // api setting
        setDebugMode:false ,
        apiDebug: false,
        perPage: 10,           //預設10筆
        apiName: "page_common_question_nopage",
        pageMode: true,
        currentPage: 0,
        totalPage: 0,

        // data
        mailList: [], 
        pagequestion_mode: [],
        qmid: "",
        thisqmid: "",
		getQuestion: [],
		getPage: [],
        disable:false,
        isCanSendMenu : false,
        isLoadFirst: false,        

            filterData: {}  ,
			
        }
    },
    methods: {
        changeToWebCallback(data){},
        onDetail(mlid){
            this.$JsBridge.callHandler('openWebView', `/message_content?mlid=${mlid}`, this.changeToWebCallback);
        },
        setOnBack(){
            this.$JsBridge.callHandler('setOnback', '', this.onBack);
        },
		onBack(){    
            this.$router.push(
            {
                name : 'Mine',
            });
        },
		onPage(page){
		 
			this.getQuestion = {};
		 
		 
            if(!this.isLoadFirst){
                this.isLoadFirst = true;
            } 
                this.disable = true;  
            this.apiName = 'page_common_question_nopage';
            oAC.getAllRecord(oAC.getApiParam(this),  this.filterData).then(
            res => 
                {
                        this.disable = true; 
						 
						
						if(res != null && res.table != null && res.table.rt != null && res.table.rt.question_mode != null  &&  res.table.rt.question_mode.length > 0){
							 
							 this.pagequestion_mode = res.table.rt.question_mode;	
							 /*
							for(var i=0;i<6;i++)
							{
								if(res.table.rt.question_mode[i] != null)
								{
									this.pagequestion_mode[i] = res.table.rt.question_mode[i];								
								}
								else
								{
									this.pagequestion_mode[i] = [];
								} 
							} */
						}
					  
                    if(res != null && res.table != null && res.table.rt && res.table.rt.question_mode  &&  res.table.rt.question_mode.length > 0){
					 
					 //this.getPage = res.table.page;	 	
					 //this.totalPage = res.table.page.item_num;	 
					 //this.currentPage = res.table.page.thispage;	
					 this.qmid = res.table.temp;	 
					
					//取得內容					
					this.apiName = 'page_common_question_getQuestion?qmid='+this.qmid;							 
					oAC.getAllRecord(oAC.getApiParam(this),  this.filterData).then(
					res2 => 
						{
								this.disable = true; 
								
							if(res2 != null && res2.table != null && res2.table.record && res2.table.record.length > 0){
							 this.getQuestion = res2.table.record;					  	
							} 
							
						}
					)
					.catch((err)=> {
						this.disable = true;
					this.filterData = {};
			//console.log("err2:");
						oltconsole.log(err);
					});
					
					
					
					
					
					
                    } 
					
                }
            )
            .catch((err)=> {
                this.disable = true;
            this.filterData = {};
			//console.log("err:");
                oltconsole.log(err);
            });
			
			 
        },
		
		onView(qmid,page){    
		console.log("onview");
			this.getQuestion = {};
			this.qmid = qmid;	
		
            if(!this.isLoadFirst){
                this.isLoadFirst = true;
            } 
			
                //this.disable = true; 

			if(qmid != null)
			{ 
			console.log(this.qmid );
			}

				
            this.apiName = 'page_common_question_getQuestion_nopage?qmid='+this.qmid ;
            oAC.getAllRecord(oAC.getApiParam(this),  this.filterData).then(
            res => 
                {
                        this.disable = true; 
                        this.isCanSendMenu = false;
						
						if(res != null && res.table != null && res.table.record.length > 0){
							this.getQuestion = res.table.record;					  		
						} 

                    if(res != null && res.table != null && res.table.rt && res.table.rt.question_mode && res.table.rt.question_mode.length > 0){ 
					 
								this.pagequestion_mode = res.table.rt.question_mode;	 
                    }
					 
					
					
					
					
					
					
					
             //   this.getCommonQuestion();
					
					
                }
            )
            .catch((err)=> {
                this.disable = true;
            this.filterData = {};
                oltconsole.log(err);
            });
			
			  
        }, 
        getCommonQuestion(){
		
        //   if(!this.isCanSendMenu)
        //   {
        //       //若filter未回來就先不call
        //       return;
        //   }
		//this.currentPage = 1;
            if(!this.isLoadFirst){
                this.isLoadFirst = true;
            } 
                this.disable = true;  
            this.apiName = 'page_common_question_nopage';
            oAC.getAllRecord(oAC.getApiParam(this),  this.filterData).then(
            res => 
                {
                        this.disable = true; 
						
                    if(res != null && res.table != null  && res.table.rt && res.table.rt.question_mode && res.table.rt.question_mode.length > 0){
					 this.pagequestion_mode = res.table.rt.question_mode;					  	
					 
					// this.getPage = res.table.page;		

					// this.totalPage = res.table.page.item_num;	
					// this.currentPage = res.table.page.thispage;						 
			this.qmid = res.table.temp;		
					
					//取得內容
					
					this.apiName = 'page_common_question_getQuestion?qmid='+this.qmid;
					oAC.getAllRecord(oAC.getApiParam(this),  this.filterData).then(
					res => 
						{
								this.disable = true; 
								
							if(res != null && res.table != null && res.table.record.length > 0){
							 this.getQuestion = res.table.record;					  	
							} 
							
						}
					)
					.catch((err)=> {
						this.disable = true;
					this.filterData = {};
						oltconsole.log(err);
					});
					
					
					
					
					
					
                    } 
					
                }
            )
            .catch((err)=> {
                this.disable = true;
            this.filterData = {};
                oltconsole.log(err);
            });
			
			 
        },
        checkFirst(){
            if(!this.isLoadFirst){
                this.getCommonQuestion();
				
            }
        },
        clearList(){
            this.totalPage = 0;
            this.currentPage = 0; 
        },
      toastCallback(data){},
    },
    mounted() {
	
        setTimeout(initNav, 600);
		
    },
    created(){  
        if (this.$JsBridge.isAndroid) {
            this.setOnBack();
        }
        this.clearList();
		
        setTimeout(this.checkFirst, 300);   
    }
}
</script> 
